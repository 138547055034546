<template>
  <div v-if="showCommon" class="wrapper">
    <div class="header">
      <router-link to="/authorization"><img alt="Обращайся" src="@/assets/img/obr-logo.svg"></router-link>
    </div>
    <div class="body">
      <div>
        <div class="body-header">
          <button class="body-header-btn" @click="this.$router.push('/authorization')"><i
              class="material-icons">keyboard_arrow_left</i></button>
          <span class="span-header">Регистрация</span>
        </div>
        <div class="main-container">
          <span class="description">Укажите тип деятельности</span>
          <div>
            <button
                class="organization"
                @click="switchCreateOrgLayout"
            >
              <i class="material-icons-outlined organization">business_center</i>Бизнес
            </button>

            <button
                class="blogger"
                @click="switchCreateBlogLayout"
            >
              <i class="material-icons-outlined blogger">campaign</i>Личная страница
            </button>

            <button
                class="vedomstvo"
                @click="$router.push('/register-federal')"
            >
              <i class="material-icons-outlined vedomstvo">account_balance</i>Ведомство
            </button>
          </div>
        </div>
      </div>
      <div v-if="windowWidth > 680" class="img-div">
        <img alt="" src="@/assets/img/newRegister/chanel-creation-bg.svg">
      </div>
    </div>
  </div>
  <CreateOrganization
      v-if="showCreateOrganization"
      :type="'default'"
      @switchCreateOrgLayout="switchCreateOrgLayout"
  ></CreateOrganization>
  <CreateBlogger
      v-if="showCreateBlogger"
      @switchCreateBlogLayout="switchCreateBlogLayout"
  ></CreateBlogger>
</template>

<script>
import CreateOrganization from '@/views/Registration/CreateOrganization';
import CreateBlogger from '@/views/Registration/CreateBlogger';

export default {
  data: () => ({
    showCommon: true,
    showCreateOrganization: false,
    showCreateBlogger: false,
    windowWidth: 0,
  }),
  name: 'NewRegister',
  components: {
    CreateOrganization,
    CreateBlogger
  },
  created() {
    this.windowWidth = window.innerWidth;
  },
  mounted() {
    if (this.$route.params.type) {
      if (this.$route.params.type === 'business') {
        if (document.querySelector('button.organization')) {
          document.querySelector('button.organization').click();
        }
      }

      if (this.$route.params.type === 'blogger') {
        if (document.querySelector('button.blogger')) {
          document.querySelector('button.blogger').click();
        }
      }
    }
  },
  computed: {
    switchCreateOrgLayout() {
      return () => {
        this.showCreateOrganization = !this.showCreateOrganization;
        this.switchCreateCmnLayout();
      }
    },
    switchCreateBlogLayout() {
      return () => {
        this.showCreateBlogger = !this.showCreateBlogger;
      }
    },
    switchCreateCmnLayout() {
      return () => {
        this.showCommon = !this.showCommon;
      }
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.header {
  height: 130px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: var(--color-white);

  a {
    margin-left: 40px;
  }
}

.body {
  background-color: var(--message-color-input);
  height: calc(100vh - 100px);
  font-family: 'SF Pro Display Regular';
  display: grid;
  grid-template-columns: 40% 60%;
  padding: 50px 0 0 40px;

  & > div:not(.img-div) {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    .body-header {
      height: 45px;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      button {
        width: 45px;
        height: 45px;
        border: none;
        border-radius: 10px;
        background-color: var(--color-light-gray);
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 15px;
        cursor: pointer;

        i {
          color: var(--color-gray);
        }
      }

      span {
        font-family: "SF Pro Display Heavy" !important;
        font-weight: 800;
        font-size: 42px;
        line-height: 52px;
        color: var(--color-dark-blue);
        cursor: default;
        height: 45px;
        display: flex;
        align-items: center;
      }
    }

    & > div:not(.body-header) {
      margin-top: 60px;
      background-color: var(--color-white);
      border: none;
      border-radius: 15px;
      width: 100%;
      height: 600px;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;

      span {
        padding: 50px;
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 140%;
        color: var(--figma-color-grey);
      }

      & > div {
        padding: 0 25px 50px 50px;
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: 45% 45%;
        grid-gap: 10px;

        button {
          border-radius: 15px;
          height: 100%;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          font-style: normal;
          font-weight: 600;
          font-size: 22px;
          line-height: 150%;

          &.organization {
            border: 1px solid var(--figma-color-blue);
            background-color: var(--figma-color-blue);
            color: var(--color-white);
          }

          &.blogger {
            border: 1px solid var(--color-blogger-border);
            background-color: var(--color-blogger-bg);
            color: var(--color-dark-blue);
          }

          &.vedomstvo {
            border: 1px solid var(--color-green);
            background-color: var(--color-admin-border);
            color: var(--color-green);
          }

          i {
            width: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            margin-bottom: 20px;
            font-size: 44px;

            &.organization {
              background-color: var(--color-dark-blue);
              color: var(--color-white);
            }

            &.blogger {
              background-color: var(--color-blogger-bg-2);
              color: var(--color-blogger-text);
            }

            &.vedomstvo {
              color: var(--color-admin-border);
              background-color: var(--color-green);
            }
          }

          i:after {
            content: "";
            display: block;
            padding-top: 100%;
          }
        }
      }
    }
  }

  .img-div {
    max-height: 100vh;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    img {
      height: 100%;
      width: 100%;
    }
  }
}

@media (max-width: 1540px) {
  button.vedomstvo, button.organization, button.blogger {
    font-size: 16px !important;
  }

  .header {
    height: 90px;

    img {
      width: 80%;
    }
  }

  .body {
    height: calc(100vh - 90px);
    padding: 35px 0 0 40px;

    & > div:not(.img-div) {

      .body-header {

        button {
          width: 30px;
          height: 30px;
          border-radius: 5px;
        }

        span {
          font-weight: 800;
          font-size: 30px;
          line-height: 37px;
        }
      }

      & > div:not(.body-header) {
        margin-top: 35px;
        width: 100%;
        height: 450px;

        span {
          padding: 35px;
          font-weight: 600;
          font-size: 20px;
          line-height: 28px;
        }

        & > div {
          padding: 0 15px 35px 35px;
          grid-template-columns: 45% 45%;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  button.vedomstvo, button.organization, button.blogger {
    font-size: 22px !important;
  }

  .body {
    grid-template-columns: 100%;
    padding: 40px 20px 0 20px;

    & > div:not(.img-div) {

      & > div:not(.body-header) {
        margin-top: 30px;
        border-radius: 15px;
        width: 100%;


        & > div {
          padding: 0 25px 50px 50px;
          display: grid;
          grid-template-columns: 45% 45%;
          grid-gap: 10px;
        }
      }
    }

    .img-div {
      width: 100%;
      height: 100%;
    }

    .organization, .blogger {
      i {
        width: 35% !important;
      }
    }
  }
}

@media (max-width: 680px) {
  button.vedomstvo, button.organization, button.blogger {
    font-size: 16px !important;
  }

  .header {

    a {
      img {
        width: 70%;
      }

      margin-left: 10px;
    }
  }

  .wrapper {
    height: 100vh;
  }

  .body {
    padding: 10px;
    overflow-y: auto;

    & > div:not(.body-header) {
      margin-top: 35px;
      width: 100%;
      height: 100%;
    }

    & > div:not(.img-div) {
      & > div:not(.body-header) {
        & > div {
          padding: 0 20px 10px 20px;
          display: grid;
          grid-template-columns: 45% 45%;
          grid-gap: 10px
        }

        & > div {
          grid-template-columns: 100%;
          grid-row-gap: 15px;

          button {
            i {
              width: 35%;
            }
          }
        }
      }
    }

    .main-container {
      height: 85% !important;
    }

    .organization, .blogger, .vedomstvo {
      height: 230px !important;

      i {
        width: 98px !important;
        height: 98px !important;
      }
    }
  }
}
</style>