import storiesModule from '@/store/modules/channel/stories';
import posts from '@/store/modules/channel/posts';
import fieldsOfActivity from '@/store/modules/channel/fieldsOfActivity';
import users from '@/store/modules/users';
import services from '@/store/modules/channel/services';
import events from '@/store/modules/channel/events';
import router from "@/router";
import {notify} from "@kyvg/vue3-notification";
import {serverError, serverFailedAccess} from "@/main";
import {API_URL} from "@/core/constants";

export default {
  state: {
    channelId: 0,
    channelInfo: {
      name: '',
      number: '',
      scope: {
        name: ''
      },
      type: {
        name: ''
      }
    },
    channels: [],
    channelsForCommunity: [],
    channelsForCommunityTotalPage: 0,
    channelsForCommunityMaxPage: 0,
    channelsForCommunityPage: 0,
    regions: [],
    channelSfers: [],
    channelThemes: [],
    channelAdministrativeThemes: [],
    addressDadata: [],
    allChannels: [],
    subscribers: [],
    types: [],
    additionalInfo: {
      contact_number_1: null,
      contact_number_2: null,
      description: null,
      socials: [],
      work_time: null,
      slogan: null,
    },
    channelAdditionalInfo: [],
  },
  getters: {
    getChannelId(state) {
      return localStorage.channelId ? localStorage.channelId : state.channelId;
    },
    getChannelData(state) {
      return state.channelInfo;
    },
    getChannelAvatar(state) {
      return state.channelInfo.image;
    },
    getChannelImage(state) {
      return state.channelInfo.background;
    },
    getChannelName(state) {
      return state.channelInfo.name;
    },
    getChannelNumber(state) {
      if (state.channelInfo.phone_number) {
        return state.channelInfo.phone_number.replace('8', '+7');
      } else {
        return '';
      }
    },
    getChannelSubscribersCount(state) {
      return state.channelInfo.subscribers_count;
    },
    getChannelType(state) {
      return state.channelInfo.scope.name;
    },
    getChannelAddress(state) {
      return state.channelInfo.address;
    },
    getChannelRegion(state) {
      return state.channelInfo.region;
    },
    getChannelScope(state) {
      return state.channelInfo.scope.id;
    },
    getChannelsList(state) {
      return state.channels;
    },
    getChannelSfers(state) {
      return state.channelSfers;
    },
    getChannelThemes(state) {
      return state.channelThemes;
    },
    getChannelAdministrativeThemes(state) {
      return state.channelAdministrativeThemes;
    },
    getAdressDadata(state) {
      return state.addressDadata;
    },
    getAllChannels(state) {
      return state.allChannels;
    },
    getSubscribers(state) {
      return state.subscribers;
    },
    getAdditionalInfo(state) {
      return state.additionalInfo;
    },
    getChannelAdditionalInfo(state) {
      return state.channelAdditionalInfo;
    },
    getChannelsForCommunity(state) {
      return state.channelsForCommunity;
    },
    getRegions(state) {
      return state.regions;
    },
    getCommunityCurrentPage(state) {
      return state.channelsForCommunityPage;
    },
    getTypes(state) {
      return state.types;
    },
  },
  mutations: {
    updateChannelId(state, channelId) {
      state.channelId = channelId;
    },
    updateChannelData(state, data) {
      state.channelInfo = data;
    },
    updateChannelsList(state, channels) {
      state.channels = channels;
    },
    updateChannelsListForCommunity(state, channels) {
      state.channelsForCommunity = channels;
    },
    updateChannelsListForCommunityTotalCount(state, totalPage) {
      state.channelsForCommunityTotalPage = totalPage;
    },
    setChannelsListForCommunityMaxPage(state, maxPage) {
      state.channelsForCommunityMaxPage = maxPage;
    },
    setChannelsListForCommunityPage(state, page) {
      state.channelsForCommunityPage = page;
    },
    updateChannel(state, updateChannel) {
      state.channelInfo = updateChannel;
    },
    addNewChannel(state, newChannel) {
      state.channels.push(newChannel);
    },
    updateChannelSfers(state, sfers) {
      state.channelSfers = sfers;
    },
    updateRegions(state, regions) {
      state.regions = regions;
    },
    updateChannelThemes(state, themes) {
      state.channelThemes = themes;
    },
    updateChannelAdministrativeThemes(state, themes) {
      state.channelAdministrativeThemes = themes;
    },
    updateAddressDadata(state, address) {
      state.addressDadata = address
    },
    updateChannels(state, channels) {
      state.allChannels = channels;
    },
    updateSubscribers(state, subscribers) {
      state.subscribers = subscribers;
    },
    updateChannelTypes(state, types) {
      state.types = types;
    },
    updateChannelInfo(state, data) {
      state.additionalInfo = data;
    },
    updateChannelDetailsInfo(state, data) {
      state.channelAdditionalInfo = data;
    },
  }
  ,
  actions: {
    async fetchChannelDetailsInformation(ctx, payload) {
      await fetch(
        `${API_URL}managers/channels/${payload}/info-full`,
        {
          method: 'GET',
          headers: {
            Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
            token: localStorage.access_token
          }
        }
      )
        .then((response) => {
          if (response.ok) {
            response.json().then((response) => {
              ctx.commit('updateChannelDetailsInfo', response.data);
            });
          } else {
            serverError(response);
          }
        })
        .catch(() => {
          serverFailedAccess();
        });
    },
    async fetchChannelInfo(ctx) {
      await fetch(
        `${API_URL}managers/channels/${localStorage.channelId}/info`,
        {
          method: 'GET',
          headers: {
            Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
            token: localStorage.access_token
          }
        }
      )
        .then((response) => {
          if (response.ok) {
            response.json().then((response) => {
              ctx.commit('updateChannelInfo', response.data);
            });
          } else {
            serverError(response);
          }
        })
        .catch(() => {
          serverFailedAccess();
        });
    },
    async changeAdditionalInfo(ctx, payload) {
      await fetch(
        `${API_URL}managers/channels/${localStorage.channelId}/info`,
        {
          method: 'POST',
          body: payload,
          headers: {
            Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
            token: localStorage.access_token
          }
        }
      )
        .then((response) => {
          if (response.ok) {
            response.json().then(() => {
              // ctx.commit('updateAdditionalInfo', response.data);
            });
          } else {
            serverError(response);
          }
        })
        .catch(() => {
          serverFailedAccess();
        });
    },
    async fetchChannelsForCommunity(ctx, payload) {
      if (ctx.state.channelsForCommunityPage != 0) {
        ctx.commit('setChannelsListForCommunityPage', ctx.state.channelsForCommunityPage + 1);
      }

      if (ctx.state.channelsForCommunityPage <= ctx.state.channelsForCommunityMaxPage) {
        await fetch(
          `${API_URL}managers/global-channels?${payload}`,
          {
            method: 'GET',
            headers: {
              Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
              token: localStorage.access_token
            }
          }
        )
          .then((response) => {
            if (response.ok) {
              response.json().then((response) => {
                if (ctx.state.channelsForCommunityPage == 0) {
                  ctx.commit('updateChannelsListForCommunity', response.data.items);
                } else {
                  ctx.commit('updateChannelsListForCommunity', [...ctx.state.channelsForCommunity, ...response.data.items]);
                }

                ctx.commit('updateChannelsListForCommunityTotalCount', response.data.total);

                if (ctx.state.channelsForCommunityPage == 0) {
                  ctx.commit('setChannelsListForCommunityMaxPage', response.data.last_page);
                  ctx.commit('setChannelsListForCommunityPage', 1);
                }
              });
            } else {
              serverError(response);
            }
          })
          .catch(() => {
            serverFailedAccess();
          });
      }
    },
    async fetchChannels(ctx) {
      await fetch(
        `${API_URL}managers/channels`,
        {
          method: 'GET',
          headers: {
            Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
            token: localStorage.access_token
          }
        }
      )
        .then((response) => {
          if (response.ok) {
            response.json().then((response) => {
              ctx.commit('updateChannelsList', response.data.items);

              router.push('/channels');
            });
          } else {
            serverError(response);
          }
        })
        .catch(() => {
          serverFailedAccess();
        });
    },
    async createChannel(ctx, payload) {
      await fetch(
        `${API_URL}managers/channels`,
        {
          method: 'POST',
          body: payload,
          headers: {
            Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
            token: localStorage.access_token
          }
        }
      )
        .then((response) => {
          if (response.ok) {
            response.json().then((response) => {
              ctx.commit('addNewChannel', response.data);
            });

            notify({
              type: 'success',
              text: 'Ваш канал успешно создан',
              speed: 1000,
              duration: 5000
            });
          } else {
            serverError(response);
          }
        })
        .catch(() => {
          serverFailedAccess();
        });
    },
    async fetchRegions(ctx) {
      await fetch(
        `${API_URL}managers/global-channels/regions`,
        {
          method: 'GET',
          headers: {
            Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
            token: localStorage.access_token
          }
        }
      )
        .then((response) => {
          if (response.ok) {
            response.json().then((response) => {
              const regions = response.data;
              let dataRegions = [];

              regions.forEach((region) => {
                dataRegions.push({id: region.region, name: region.region});
              });

              ctx.commit('updateRegions', dataRegions);
            })
          } else {
            serverError(response);
          }
        })
        .catch(() => {
          serverFailedAccess();
        });
    },
    async fetchChannelSfers(ctx) {
      await fetch(
        `${API_URL}managers/scopes/short`,
        {
          method: 'GET',
          headers: {
            Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
            token: localStorage.access_token
          }
        }
      )
        .then((response) => {
          if (response.ok) {
            response.json().then((response) => {
              ctx.commit('updateChannelSfers', response.data);
            })
          } else {
            serverError(response);
          }
        })
        .catch(() => {
          serverFailedAccess();
        });
    },
    async fetchChannelThemes(ctx) {
      await fetch(
        `${API_URL}managers/scopes/short-themes`,
        {
          method: 'GET',
          headers: {
            Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
            token: localStorage.access_token
          }
        }
      )
        .then((response) => {
          if (response.ok) {
            response.json().then((response) => {
              ctx.commit('updateChannelThemes', response.data);
            })
          } else {
            serverError(response);
          }
        })
        .catch(() => {
          serverFailedAccess();
        });
    },
    async fetchChannelAdministrativeThemes(ctx) {
      await fetch(
        `${API_URL}managers/scopes/short-administrative`,
        {
          method: 'GET',
          headers: {
            Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
            token: localStorage.access_token
          }
        }
      )
        .then((response) => {
          if (response.ok) {
            response.json().then((response) => {
              ctx.commit('updateChannelAdministrativeThemes', response.data);
            })
          } else {
            serverError(response);
          }
        })
        .catch(() => {
          serverFailedAccess();
        });
    },
    async chooseChannel(ctx, payload) {
      if (payload.channelId) {
        ctx.commit('updateChannelId', payload.channelId);
        localStorage.channelId = payload.channelId;
      }

      await fetch(
        `${API_URL}managers/channels/${payload.channelId}`,
        {
          method: 'GET',
          headers: {
            Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
            token: localStorage.access_token
          }
        }
      )
        .then((response) => {
          if (response.ok) {
            response.json().then((response) => {
              ctx.commit('updateChannelData', response.data);
              localStorage.channelMarking = response.data.marking?.toString() || 'false';
              localStorage.channelToken = response.data.token?.toString() || '';

              if (payload.needPush === undefined ? true : payload.needPush) {
                router.push('/my-channel');
              }
            })
          } else {
            serverError(response);
          }
        })
        .catch(() => {
          serverFailedAccess();
        });
    },
    async changeChannel(ctx, payload) {
      await fetch(
        `${API_URL}managers/channels/${localStorage.channelId}`,
        {
          method: 'POST',
          body: payload,
          headers: {
            Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
            token: localStorage.access_token
          }
        }
      )
        .then((response) => {
          if (response.ok) {
            response.json().then((response) => {
              ctx.commit('updateChannel', response.data);
            });

            notify({
              type: 'success',
              text: 'Ваш канал успешно изменен',
              speed: 1000,
              duration: 5000
            });
          } else {
            serverError(response);
          }
        })
        .catch(() => {
          serverFailedAccess();
        });
    },
    async fetchAddressDadata(ctx, payload) {
      await fetch(
        `${API_URL}addresses?search=${payload}`,
        {
          method: 'GET',
          headers: {
            Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
          },

        }
      )
        .then((response) => {
          if (response.ok) {
            response.json().then((response) => {
              ctx.commit('updateAddressDadata', response.data)
            })
          } else {
            serverError(response);
          }
        })
        .catch(() => {
          serverFailedAccess();
        });
    },
    async fetchAllChannels(ctx) {
      await fetch(
        `${API_URL}managers/channels/with-addresses`,
        {
          method: 'GET',
          headers: {
            Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
            token: localStorage.access_token
          }
        }
      )
        .then((response) => {
          if (response.ok) {
            response.json().then((response) => {
              ctx.commit('updateChannels', response.data);
            });
          } else {
            serverError(response);
          }
        })
        .catch(() => {
          serverFailedAccess();
        });
    },
    async fetchSubscribers(ctx) {
      await fetch(
        `${API_URL}managers/channels/${localStorage.channelId}/subscribers`,
        {
          method: 'GET',
          headers: {
            Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
            token: localStorage.access_token
          }
        }
      )
        .then((response) => {
          if (response.ok) {
            response.json().then((response) => {
              ctx.commit('updateSubscribers', response.data);
            });
          } else {
            serverError(response);
          }
        })
        .catch(() => {
          serverFailedAccess();
        });
    },
    async fetchChannelTypes(ctx) {
      await fetch(
        `${API_URL}managers/channels/types`,
        {
          method: 'GET',
          headers: {
            Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
            token: localStorage.access_token
          }
        }
      )
        .then((response) => {
          if (response.ok) {
            response.json().then((response) => {
              ctx.commit('updateChannelTypes', response.data);
            });
          } else {
            serverError(response);
          }
        })
        .catch(() => {
          serverFailedAccess();
        });
    },
  },
  modules: {
    users,
    posts,
    fieldsOfActivity,
    storiesModule,
    services,
    events,
  }
}