<template>
  <div v-if="!showModeration" class="wrapper">
    <div class="header">
      <router-link to="/authorization"><img alt="Обращайся" src="@/assets/img/obr-logo.svg"></router-link>
    </div>
    <div class="body">
      <div>
        <div class="body-header">
          <div>
            <button
                @click="prevStep"
            ><i class="material-icons">keyboard_arrow_left</i></button>
            <span>Создание организации</span>
          </div>
<!--          <span class="step-span">-->
<!--            <span class="active-span">{{ stepNumber }}</span>&nbsp;/ 2-->
<!--          </span>-->
        </div>
        <div class="form">
          <div v-if="stepNumber === 1">
<!--            <span>Организация</span>-->
<!--            <span></span>-->

            <div class="left input-container">
              <input v-model="createOrganizationData.organizationName"
                     :class="{ 'input-error': v$.createOrganizationData.organizationName.$errors.length }"
                     class="left"
                     data-type="organization_name"
                     placeholder="Наименование организации"
                     @blur="v$.createOrganizationData.organizationName.$touch"
                     @change="addRegisterData">
              <p v-if="v$.createOrganizationData.organizationName.$error" class="text-error">Введите корректное
                наименование организации</p>
            </div>
<!--            <div class="input-container">-->
<!--              <input v-model="createOrganizationData.bankCode"-->
<!--                     :class="{ 'input-error': v$.createOrganizationData.bankCode.$errors.length }"-->
<!--                     data-type="bank_code"-->
<!--                     maxlength="9"-->
<!--                     placeholder="БИК"-->
<!--                     @blur="v$.createOrganizationData.bankCode.$touch"-->
<!--                     @change="addRegisterData">-->
<!--              <p v-if="v$.createOrganizationData.bankCode.$error" class="text-error">Введите корректный БИК</p>-->
<!--            </div>-->

            <div class="left input-container">
              <input v-model="createOrganizationData.taxNumber"
                     :class="{ 'input-error': v$.createOrganizationData.taxNumber.$errors.length }"
                     class="left"
                     data-type="tax_number"
                     maxlength="12"
                     minlength="10"
                     placeholder="ИНН"
                     @blur="v$.createOrganizationData.taxNumber.$touch"
                     @change="addRegisterData">
              <p v-if="v$.createOrganizationData.taxNumber.$error" class="text-error">Введите корректный ИНН</p>
            </div>

            <div class="input-container">
              <input v-model="createOrganizationData.email"
                     :class="{ 'input-error': v$.createOrganizationData.email.$errors.length }"
                     data-type="email"
                     placeholder="E-mail"
                     type="email"
                     @blur="v$.createOrganizationData.email.$touch"
                     @change="addRegisterData">
              <p v-if="v$.createOrganizationData.email.$error" class="text-error">Введите корректный E-mail</p>
            </div>
<!--            <div class="input-container">-->
<!--              <input v-model="createOrganizationData.bankName"-->
<!--                     :class="{ 'input-error': v$.createOrganizationData.bankName.$errors.length }"-->
<!--                     data-type="bank_name"-->
<!--                     placeholder="Наименование банка"-->
<!--                     @blur="v$.createOrganizationData.bankName.$touch"-->
<!--                     @change="addRegisterData">-->
<!--              <p v-if="v$.createOrganizationData.bankName.$error" class="text-error">Введите корректное наименование-->
<!--                банка</p>-->
<!--            </div>-->

            <div class="input-container">
              <input v-model="createOrganizationData.organizationPhone"
                     v-maska="'+7 (###) ###-##-##'"
                     :class="{ 'input-error': v$.createOrganizationData.organizationPhone.$errors.length }"
                     data-type="phone"
                     placeholder="Телефон"
                     type="tel"
                     @blur="v$.createOrganizationData.organizationPhone.$touch"
                     @change="addRegisterData">
              <p v-if="v$.createOrganizationData.organizationPhone.$error" class="text-error">Введите корректный
                Телефон</p>
            </div>

<!--            <div class="left input-container">-->
<!--              <input v-model="createOrganizationData.taxReasonNumber"-->
<!--                     :class="{ 'input-error': v$.createOrganizationData.taxReasonNumber.$errors.length }"-->
<!--                     class="left"-->
<!--                     data-type="tax_reason_number"-->
<!--                     maxlength="9"-->
<!--                     placeholder="КПП"-->
<!--                     @blur="v$.createOrganizationData.taxReasonNumber.$touch"-->
<!--                     @change="addRegisterData">-->
<!--              <p v-if="v$.createOrganizationData.taxReasonNumber.$error" class="text-error">Введите корректный КПП</p>-->
<!--            </div>-->
            <div class="left input-container">
              <input
                  v-model="createOrganizationData.legalAddress"
                  :class="{ 'input-error': v$.createOrganizationData.legalAddress.$errors.length }"
                  data-type="legal_address"
                  placeholder="Юридический адрес"
                  @blur="v$.createOrganizationData.legalAddress.$touch"
                  @change="addRegisterData"
                  @input="searchParam"
              >
              <p v-if="v$.createOrganizationData.legalAddress.$error" class="text-error">Введите корректный юридический
                адрес</p>
              <div v-if="showSearchResult" class="search-result">
                <span v-for="(item, index) in getAdressDadata" :key="index" @click="getDadataValue($event,index)">
                  {{ item }}
                </span>
              </div>
            </div>

            <div class="channel-instruction">
              <span>Ознакомлен с <router-link target="_blank"
                                              to="/usage-rules">правилами пользования личного кабинета</router-link></span>
              <input
                  v-model="createOrganizationData.isRules"
                  type="checkbox"
                  @blur="v$.createOrganizationData.isRules.$touch">
            </div>

<!--            <div class="left input-container">-->
<!--              <input v-model="createOrganizationData.billingNumber"-->
<!--                     :class="{ 'input-error': v$.createOrganizationData.billingNumber.$errors.length }"-->
<!--                     class="left"-->
<!--                     data-type="billing_number"-->
<!--                     maxlength="20"-->
<!--                     placeholder="Расчетный счет организации"-->
<!--                     @blur="v$.createOrganizationData.billingNumber.$touch"-->
<!--                     @change="addRegisterData">-->
<!--              <p v-if="v$.createOrganizationData.billingNumber.$error" class="text-error">Введите корректный расчетный-->
<!--                счет организации</p>-->
<!--            </div>-->

          </div>
          <div v-else>
            <span class="director">Руководитель</span>
            <span v-if="windowWidth > 680">Ответственное лицо</span>

            <div v-if="windowWidth > 680" class="left input-container">
              <input v-model="createOrganization.fioDirector"
                     :class="{ 'input-error': v$.createOrganization.fioDirector.$errors.length }"
                     class="left"
                     data-type="director_full_name"
                     placeholder="ФИО руководителя"
                     @blur="v$.createOrganization.fioDirector.$touch" @change="addRegisterData">
              <p v-if="v$.createOrganization.fioDirector.$error" class="text-error left">Введите корректное ФИО
                руководителя</p>
            </div>
            <div v-if="windowWidth > 680" class="input-container">
              <input v-model="createOrganization.fioResponsible"
                     :class="{ 'input-error': v$.createOrganization.fioResponsible.$errors.length }"
                     data-type="responsible_full_name"
                     placeholder="ФИО ответственного лица"
                     @blur="v$.createOrganization.fioResponsible.$touch" @change="addRegisterData">
              <p v-if="v$.createOrganization.fioResponsible.$error" class="text-error">Введите корректное ФИО
                ответственного лица</p>
            </div>

            <div v-if="windowWidth > 680" class="left input-container">
              <input v-model="createOrganization.position"
                     :class="{ 'input-error': v$.createOrganization.position.$errors.length }"
                     class="left"
                     data-type="position"
                     placeholder="Должность"
                     @blur="v$.createOrganization.position.$touch"
                     @change="addRegisterData">
              <p v-if="v$.createOrganization.position.$error" class="text-error">Введите корректную должность</p>
            </div>
            <div v-if="windowWidth > 680" class="input-container">
              <input v-model="createOrganization.phone"
                     v-maska="'+7 (###) ###-##-##'"
                     :class="{ 'input-error': v$.createOrganization.phone.$errors.length }"
                     data-type="responsible_phone"
                     placeholder="Телефон"
                     type="tel"
                     @blur="v$.createOrganization.phone.$touch"
                     @change="addRegisterData">
              <p v-if="v$.createOrganization.phone.$error" class="text-error">Введите корректный Телефон</p>
            </div>

            <div v-if="windowWidth > 680" class="left input-container">
              <input v-model="createOrganization.orderNumber"
                     class="left"
                     data-type="order_number"
                     placeholder="Номер приказа"
                     @change="addRegisterData">
            </div>
            <div v-if="windowWidth > 680 && getUserData.secret_id === undefined" class="input-container">
              <input v-model="createOrganization.id"
                     :class="{ 'input-error': v$.createOrganization.id.$errors.length }"
                     placeholder="Идентификатор пользователя"
                     @blur="v$.createOrganization.id.$touch">
              <p v-if="v$.createOrganization.id.$error" class="text-error">Введите корректный идентификатор
                пользователя</p>
            </div>

            <div v-if="windowWidth > 680" class="channel-instruction">
              <span>Ознакомлен с <router-link target="_blank"
                                              to="/usage-rules">правилами пользования личного кабинета</router-link></span>
              <input
                  v-model="createOrganization.isRules"
                  type="checkbox"
                  @blur="v$.createOrganization.isRules.$touch">
            </div>
            <div v-if="windowWidth > 680" class="pseudo-info-block">
              <span>
              Вы сможете найти идентификатор в настройках профиля в мобильном приложении
            </span>
              <span class="pseudo-info-icon">i</span>
            </div>

            <div v-if="windowWidth <= 680" class="left input-container">
              <input v-model="createOrganization.fioDirector"
                     :class="{ 'input-error': v$.createOrganization.fioDirector.$errors.length }"
                     class="left"
                     data-type="director_full_name"
                     placeholder="ФИО руководителя"
                     @blur="v$.createOrganization.fioDirector.$touch" @change="addRegisterData">
              <p v-if="v$.createOrganization.fioDirector.$error" class="text-error left">Введите корректное ФИО
                руководителя</p>
            </div>
            <div v-if="windowWidth <= 680" class="left input-container">
              <input v-model="createOrganization.position"
                     :class="{ 'input-error': v$.createOrganization.position.$errors.length }"
                     class="left"
                     data-type="position"
                     placeholder="Должность"
                     @blur="v$.createOrganization.position.$touch"
                     @change="addRegisterData">
              <p v-if="v$.createOrganization.position.$error" class="text-error">Введите корректную должность</p>
            </div>
            <div v-if="windowWidth <= 680" class="left input-container">
              <input v-model="createOrganization.orderNumber"
                     class="left"
                     data-type="order_number"
                     placeholder="Номер приказа"
                     @change="addRegisterData">

            </div>
            <span v-if="windowWidth <= 680" class="responsible">Ответственное лицо</span>
            <div v-if="windowWidth <= 680" class="input-container">
              <input v-model="createOrganization.fioResponsible"
                     :class="{ 'input-error': v$.createOrganization.fioResponsible.$errors.length }"
                     data-type="responsible_full_name"
                     placeholder="ФИО ответственного лица"
                     @blur="v$.createOrganization.fioResponsible.$touch" @change="addRegisterData">
              <p v-if="v$.createOrganization.fioResponsible.$error" class="text-error">Введите корректное ФИО
                ответственного лица</p>
            </div>
            <div v-if="windowWidth <= 680" class="input-container">
              <input v-model="createOrganization.phone"
                     v-maska="'+7 (###) ###-##-##'"
                     :class="{ 'input-error': v$.createOrganization.phone.$errors.length }"
                     data-type="responsible_phone"
                     placeholder="Телефон"
                     type="tel"
                     @blur="v$.createOrganization.phone.$touch"
                     @change="addRegisterData">
              <p v-if="v$.createOrganization.phone.$error" class="text-error">Введите корректный Телефон</p>
            </div>
            <div v-if="windowWidth <= 680 && getUserData.secret_id === undefined" class="input-container">
              <input v-model="createOrganization.id"
                     :class="{ 'input-error': v$.createOrganization.id.$errors.length }"
                     placeholder="Идентификатор пользователя"
                     @blur="v$.createOrganization.id.$touch">
              <p v-if="v$.createOrganization.id.$error" class="text-error">Введите корректный идентификатор
                пользователя</p>
            </div>
            <div v-if="windowWidth <= 680" class="channel-instruction">
              <span>Ознакомлен с <router-link target="_blank"
                                              to="/usage-rules">правилами пользования личного кабинета</router-link></span>
              <input
                  v-model="createOrganization.isRules"
                  type="checkbox"
                  @blur="v$.createOrganization.isRules.$touch">
            </div>
            <div v-if="windowWidth <= 680" class="pseudo-info-block">0
              <span>
              Вы сможете найти идентификатор в настройках профиля в мобильном приложении
            </span>
              <span class="pseudo-info-icon">i</span>
            </div>

          </div>
          <button
              :disabled="v$.createOrganizationData.$invalid"
              @click="nextStep"
          >Создать
          </button>
        </div>
      </div>
      <div v-if="windowWidth > 680" class="img-div">
        <img alt="" src="@/assets/img/newRegister/Businessman.svg">
      </div>
    </div>
  </div>
  <ModerationOrganization
      v-if="showModeration"
  ></ModerationOrganization>
</template>

<script>
import ModerationOrganization from '@/views/Registration/ModerationOrganization';
import {mapActions, mapGetters} from 'vuex';
import useVuelidate from '@vuelidate/core'
import {email, maxLength, minLength, numeric, required, sameAs} from "@vuelidate/validators";
import {validPhone} from "@/validators/validators";

export default {
  setup() {
    return {v$: useVuelidate()}
  },
  data: () => ({
    stepNumber: 1,
    showModeration: false,
    showSearchResult: false,
    registerData: {
      'organization_name': {
        value: 'asdasdasd'
      },
      'legal_address': {
        value: 'asdasdasd'
      },
      'phone': {
        value: '81222222222'
      },
      'email': {
        value: 'i.antropov@obrashaysya.ru'
      },
      'tax_number': {
        value: '3664069397'
      },
      'tax_reason_number': {
        value: '123456789'
      },
      'billing_number': {
        value: '12345678912345678912'
      },
      'bank_code': {
        value: '12345678912345678912'
      },
      'bank_name': {
        value: 'asdasdasd'
      },
      'correspondent_number': {
        value: '12345678912345678912'
      },
      'director_full_name': {
        value: 'asfasf asffas asfasf'
      },
      'position': {
        value: 'asfasf'
      },
      'order_number': {
        value: '12'
      },
      'responsible_full_name': {
        value: 'asfasf asffas asfasf'
      },
      'responsible_phone': {
        value: '81222222222'
      }
    },
    createOrganizationData: {
      organizationName: '',
      // bankCode: '',
      legalAddress: '',
      // bankName: '',
      taxNumber: '',
      organizationPhone: '',
      // taxReasonNumber: '',
      email: '',
      // billingNumber: '',
      // correspondentNumber: '',
      isRules: false,
    },
    createOrganization: {
      fioDirector: '',
      fioResponsible: '',
      position: '',
      phone: '',
      orderNumber: '',
      id: '',
      isRules: false
    },
    windowWidth: 0,
  }),
  created() {
    this.windowWidth = window.innerWidth;
  },
  mounted() {
    if (this.$route.query?.secretID !== undefined) {
      this.createOrganization.id = this.$route.query?.secretID;
    }
  },
  validations() {
    return {
      createOrganizationData: {
        organizationName: {
          required,
        },
        // bankCode: {
        //   numeric,
        //   required,
        //   minLength: minLength(9),
        //   maxlength: maxLength(9),
        //
        // },
        legalAddress: {
          required
        },
        // bankName: {
        //   required,
        // },
        taxNumber: {
          required,
          minLength: minLength(10),
          maxlength: maxLength(12),
          numeric,
        },
        isRules: {
          sameAs: sameAs(true)
        },
        organizationPhone: {
          required,
          name_validation: {
            $validator: validPhone
          }
        },
        // taxReasonNumber: {
        //   minLength: minLength(9),
        //   maxlength: maxLength(9),
        //   numeric,
        // },
        email: {
          required,
          email,
        },
        // billingNumber: {
        //   required,
        //   minLength: minLength(20),
        //   maxlength: maxLength(20),
        //   numeric,
        // },
      },
      createOrganization: {
        fioDirector: {
          required,
        },
        fioResponsible: {
          required,
        },
        position: {
          required,
        },
        phone: {
          required,
          name_validation: {
            $validator: validPhone
          }
        },
        id: {
          required,
        },
        isRules: {
          sameAs: sameAs(true)
        }
      }
    }
  },
  name: 'CreateOrganization',
  components: {
    ModerationOrganization
  },
  props: [
    'type'
  ],
  emits: [
    'switchCreateOrgLayout'
  ],
  computed: {
    ...mapGetters(
        [
          'getUserData',
          'getAdressDadata',
        ]
    ),
    changeStepNumber() {
      return (stepNumber) => {
        this.stepNumber = parseInt(stepNumber);
      }
    },
    switchModeration() {
      return () => {
        if (this.$isShowErrorNotify) {
          this.showModeration = !this.showModeration;
        }
      }
    },
    switchShowSearchResult() {
      return (event) => {
        this.showSearchResult = event.currentTarget.value !== '';
      }
    },
  },
  methods: {
    ...mapActions(
        [
          'apiCreateOrganization',
          'fetchAddressDadata',
        ]
    ),
    addRegisterData(event) {
      this.registerData[event.currentTarget.dataset.type].value = event.currentTarget.value;
    },
    getDadataValue(event, index) {
      this.createOrganizationData.legalAddress = this.getAdressDadata[index]
      this.showSearchResult = false
    },
    searchParam(event) {
      this.fetchAddressDadata(event.currentTarget.value)
      this.switchShowSearchResult(event)
    },
    nextStep() {
      // if (this.stepNumber === 1) {
      //   this.changeStepNumber(2);
      // } else {
        const payload = new FormData();

        payload.append('organization_name', this.createOrganizationData.organizationName);
        payload.append('legal_address', this.createOrganizationData.legalAddress);
        payload.append('phone', this.createOrganizationData.organizationPhone);
        payload.append('email', this.createOrganizationData.email);
        payload.append('tax_number', this.createOrganizationData.taxNumber);
        // payload.append('tax_reason_number', this.createOrganizationData.taxReasonNumber);
        // payload.append('billing_number', this.createOrganizationData.billingNumber);
        // payload.append('bank_code', this.createOrganizationData.bankCode);
        // payload.append('bank_name', this.createOrganizationData.bankName);
        // payload.append('director_full_name', this.createOrganization.fioDirector);
        // payload.append('position', this.createOrganization.position);
        // payload.append('order_number', this.createOrganization.orderNumber);
        // payload.append('responsible_full_name', this.createOrganization.fioResponsible);
        // payload.append('responsible_phone', this.createOrganization.phone);
        payload.append('secret_id', this.getUserData.secret_id === undefined ? this.createOrganization.id : this.getUserData.secret_id);

        if (this.type === 'federal') {
          payload.append('is_administrative', '1');
        }

        this.apiCreateOrganization(payload);

        setTimeout(() => {
          this.switchModeration();
        }, 100);
      // }
    },
    prevStep() {
      if (this.stepNumber === 2) {
        this.changeStepNumber(1);
      } else {
        if (this.type === 'default') {
          this.$emit('switchCreateOrgLayout');
        }

        if (this.type === 'federal') {
          this.$router.push('/register/choose');
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.input-error {
  border-color: var(--color-red) !important;
}

.text-error {
  color: var(--color-red);
  margin-top: -10px;
  margin-bottom: 10px;
  text-align: center;
  font-size: 16px;
}

.input-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-right: 10px;

  input {
    width: 100%;
  }
}

.header {
  height: 130px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: var(--color-white);

  a {
    margin-left: 40px;
  }
}

.body {
  background-color: var(--message-color-input);
  height: calc(100vh - 130px);
  font-family: 'SF Pro Display Regular';
  display: grid;
  grid-template-columns: 50% 50%;
  padding: 30px 0 0 40px;

  & > div:not(.img-div) {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;

    .body-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      div {
        display: flex;
        justify-content: center;
        align-items: center;

        button {
          width: 45px;
          height: 45px;
          border: none;
          border-radius: 10px;
          background-color: var(--color-light-gray);
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 15px;
          cursor: pointer;

          i {
            color: var(--color-gray);
          }
        }

        span {
          font-family: "SF Pro Display Heavy" !important;
          font-style: normal;
          font-weight: 700;
          font-size: 32px;
          line-height: 52px;
          color: var(--color-dark-blue);
          cursor: default;
        }
      }

      span.step-span {
        width: 82px;
        height: 82px;
        color: var(--figma-color-grey);
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        border-radius: 15px;
        background-color: var(--color-white);
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 140%;

        .active-span {
          color: var(--color-dark-blue);
        }
      }
    }

    & > div:not(.body-header) {
      margin-top: 30px;
      background-color: var(--color-white);
      border: none;
      border-radius: 15px;
      width: 100%;
      height: 80%;
      max-width: 1060px;
      max-height: 750px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 50px;

      & > div {
        display: grid;
        grid-template-columns: 50% 50%;
        grid-auto-rows: auto;
        width: 100%;

        & > span {
          display: flex;
          margin-bottom: 25px;
          color: var(--figma-color-grey);
        }

        & > div {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;

          &.channel-instruction {
            width: 98%;
            height: 82px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: none;
            background-color: var(--color-chanel-bg-2);
            border-radius: 15px;
            padding-right: 10px;

            span {
              padding: 0 20px;

              a {
                color: var(--color-blue);
                text-decoration: underline;
                cursor: pointer;
              }
            }

            input {
              margin: 0 20px;
              width: 20px;
              min-width: 20px;
              height: 20px;
              min-height: 20px;
              border-radius: 10px;
              border: none;
              background-color: var(--color-blue);
              color: var(--color-white);
            }
          }

          &.pseudo-info-block {
            display: flex;
            justify-content: center;
            align-items: center;
          }

          span.pseudo-info-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 27px;
            min-width: 27px;
            height: 27px;
            min-height: 27px;
            margin-right: 10px;
            background-color: var(--color-blue);
            color: var(--color-white);
            border: none;
            border-radius: 8px;
            font-size: 18px;
            font-family: 'SF Pro Text Bold';
            cursor: help;
          }

          span:not(.pseudo-info-icon) {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 150%;
            color: var(--figma-color-dark-grey);
          }
        }
      }

      input {
        height: 82px;
        border: 1px solid var(--modal-appeals-color-border);
        background-color: var(--modal-appeals-color-text);
        border-radius: 15px;
        color: var(--figma-color-dark-blue);
        margin-bottom: 10px;
        padding-left: 15px;

        &.left {
          margin-right: 10px;
        }

        &::placeholder {
          color: var(--figma-color-grey);
        }

        &:active, &:hover, &:focus {
          outline: 0;
          outline-offset: 0;
        }
      }

      button {
        height: 82px;
        min-height: 82px;
        width: 100%;
        border-radius: 15px;
        border: none;
        background-color: var(--color-blue);
        color: var(--color-white);
        margin-top: 15px;
        cursor: pointer;

        &:disabled {
          cursor: no-drop;
          opacity: 0.6;
        }
      }
    }
  }

  .search-result {
    display: block;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: var(--modal-appeals-color-text);
    color: var(--color-dark-blue);
    width: 100%;
    border-radius: 12px;
    border: 1px solid var(--color-gray);
    margin-right: 20px;
    max-height: 250px;
    overflow-y: auto;
    overflow-x: hidden;

    span {
      margin: 3px;
      width: 100%;
      display: flex;
      padding: 5px;
      border-bottom: 1px solid var(--color-light-gray);
      cursor: pointer;
      font-size: 14px!important;

      &:hover {
        background-color: var(--input-placeholder-color);
        color: var(--input-container-bg);
        border-radius: 8px;
      }
    }
  }

  .form {
    display: flex!important;
    justify-content: flex-start!important;
    align-items: flex-start!important;
    flex-direction: column!important;
    padding-top: 20px!important;
    margin: 0!important;
    width: auto!important;
    height: auto!important;
  }

  .img-div {
    max-height: 100vh;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    img {
      height: 90%;
      width: 90%;
      object-fit: cover;
    }
  }
}

@media (max-width: 1540px) {
  .text-error {
    color: var(--color-red);
    margin-top: 0;
    margin-bottom: 5px;
    text-align: center;
    font-size: 9px;
  }

  .header {
    height: 90px;

    img {
      width: 80%;
    }
  }

  .body {
    height: calc(100vh - 140px);
    padding: 25px 0 0 40px;

    & > div:not(.img-div) {

      .body-header {

        div {

          button {
            width: 30px;
            height: 30px;
          }

          span {
            font-weight: 800;
            font-size: 30px;
            line-height: 37px;
          }
        }

        span.step-span {
          width: 55px;
          height: 55px;
          border-radius: 12px;
          font-weight: 700;
          font-size: 12px;
          line-height: 18px;
        }
      }

      & > div:not(.body-header) {
        margin-top: 15px;
        border-radius: 15px;
        width: 100%;
        height: 75%;
        padding: 35px;
        justify-content: space-around;

        & > div {
          & > span {
            margin-bottom: 20px;
          }

          & > div {

            &.channel-instruction {
              height: 58px;
              border-radius: 13px;
              padding-right: 10px;


              input {
                margin: 0 10px;
                width: 15px;
                min-width: 15px;
                height: 15px;
                min-height: 15px;
                border-radius: 10px;
              }
            }


            span.pseudo-info-icon {
              width: 20px;
              min-width: 20px;
              height: 20px;
              min-height: 20px;
            }

            span:not(.pseudo-info-icon) {
              font-size: 8px;
              line-height: 12px;
            }
          }
        }

        input {
          height: 60px;
          border-radius: 12px;
          margin-bottom: 5px;
          font-size: 12px;

        }

        button {
          height: 60px;
          min-height: 58px;
          border-radius: 13px;
          margin-top: 10px;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .body {
    grid-template-columns: 100%;
    padding: 40px 20px 0 20px;

    .img-div {
      max-height: 100vh;
      margin: -65px auto 0;
      display: flex;
      justify-content: start;
      align-items: center;

      img {
        height: 90%;
        width: 90%;
        object-fit: cover;
      }
    }

    & > div:not(.img-div) {

      .body-header {

        div {

          button {
            width: 40px;
            height: 40px;
          }

          span {
            font-weight: 800;
            font-size: 32px;
            line-height: 52px;
          }
        }

        span.step-span {
          width: 40px;
          height: 40px;
          border-radius: 8px;
          font-weight: 700;
          font-size: 12px;
          line-height: 18px;
        }
      }

      & > div:not(.body-header) {
        margin-top: 35px;
        border-radius: 15px;
        width: 100%;
        height: 85%;
        padding: 35px;
        justify-content: space-around;

        & > div {
          & > span {
            margin-bottom: 20px;
          }

          & > div {

            &.channel-instruction {
              height: 58px;
              border-radius: 13px;
              padding-right: 10px;


              input {
                margin: 0 10px;
                width: 15px;
                min-width: 15px;
                height: 15px;
                min-height: 15px;
                border-radius: 10px;
              }
            }


            span.pseudo-info-icon {
              width: 20px;
              min-width: 20px;
              height: 20px;
              min-height: 20px;
            }

            span:not(.pseudo-info-icon) {
              font-size: 8px;
              line-height: 12px;
            }
          }
        }

        input {
          border-radius: 12px;
          margin-bottom: 15px;
          font-size: 12px;

        }

        button {
          min-height: 58px;
          border-radius: 13px;
          margin-top: 10px;
        }
      }
    }
  }
}

@media (max-width: 680px) {
  .header {

    a {
      img {
        width: 70%;
      }

      margin-left: 10px;
    }
  }

  .wrapper {
    height: calc(var(--vh, 1vh) * 100);
    overflow-y: hidden;
  }

  .body {
    padding: 10px;
    overflow-y: auto;

    & > div:not(.img-div) {

      .body-header {
        align-items: flex-start;

        div {
          align-items: flex-start;

          button {
            width: 35px;
            height: 35px;
            margin-top: 7px;
          }

          span {
            font-weight: 800;
            font-size: 28px;
            line-height: 41px;

          }
        }

        span.step-span {
          margin-top: 7px;
          width: 35px;
          height: 35px;
          border-radius: 8px;
          font-weight: 700;
          font-size: 12px;
          line-height: 18px;
        }
      }

      & > div:not(.body-header) {
        margin-top: 25px;
        border-radius: 15px;
        width: 100%;
        height: 85%;
        padding: 0;
        justify-content: space-around;
        background-color: #F7F7F7;


        & > div {
          .input-container {
            padding-right: 0 !important;
          }

          & > span {
            margin-bottom: 20px;
          }

          & > div {

            &.channel-instruction {
              height: 58px;
              border-radius: 13px;
              padding-right: 10px;


              input {
                margin: 0 10px;
                width: 15px;
                min-width: 15px;
                height: 15px;
                min-height: 15px;
                border-radius: 10px;
              }
            }


            span.pseudo-info-icon {
              width: 20px;
              min-width: 20px;
              height: 20px;
              min-height: 20px;
            }

            span:not(.pseudo-info-icon) {
              font-size: 8px;
              line-height: 12px;
            }
          }
        }

        input {
          border-radius: 12px;
          margin-bottom: 8px;
          font-size: 12px;
          margin-right: 0 !important;

        }

        button {
          min-height: 58px;
          border-radius: 13px;
          margin-top: 10px;
        }
      }
    }

    .channel-instruction, .pseudo-info-block {
      margin-bottom: 10px;
    }
  }

  .director {
    margin: 0 0 8px 0 !important
  }

  .body > div:not(.img-div) > div:not(.body-header) > div {
    grid-template-columns: 100%;

    & > span {
      margin-top: 20px;
      margin-bottom: 0;
    }
  }

  .responsible {
    margin-bottom: 8px !important;
    margin-top: 25px !important;
  }

  .step-span {
    min-width: 35px;
    max-height: 35px;
  }
}
</style>