import {serverError, serverFailedAccess} from "@/main";
import {API_URL} from "@/core/constants";

export default {
  state: {
    events: [],
    eventsCategories: [],
    currentPage: 0,
    maxPage: 0,
  },
  getters: {
    getEvents(state) {
      return state.events;
    },
    getEventsCategories(state) {
      return state.eventsCategories;
    },
    getEventPage(state) {
      return state.currentPage;
    },
    getEventMaxPage(state) {
      return state.maxPage;
    },
  },
  mutations: {
    updateEvents(state, events) {
      state.events = events;
    },
    updateEventsCategories(state, categories) {
      state.eventsCategories = categories;
    },
    addEvent(state, event) {
      state.events.unshift(event);
    },
    updateEvent(state, event) {
      const index = state.events.findIndex(eventItem => eventItem.id.toString() === event.id.toString());

      state.events[index] = event;
    },
    removeEvent(state, eventId) {
      state.events = state.events.filter(eventItem => eventItem.id.toString() !== eventId.toString());
    },
    setEventCurrentPage(state, page) {
      state.currentPage = page;
    },
    setEventMaxPage(state, page) {
      state.maxPage = page;
    },
  },
  actions: {
    async fetchEvents(ctx, payload) {
      if (ctx.state.currentPage != 0) {
        ctx.commit('setEventCurrentPage', ctx.state.currentPage + 1);
      }

      if (ctx.state.currentPage <= ctx.state.maxPage) {
        await fetch(
            `${API_URL}managers/channels/${localStorage.channelId}/events${payload}`,
            {
              method: 'GET',
              headers: {
                Authorization: `${localStorage.token_type} ${localStorage.access_token}`
              }
            }
        )
            .then((response) => {
              if (response.ok) {
                response.json().then((response) => {
                  if (ctx.state.currentPage == 0) {
                    ctx.commit('updateEvents', response.data.items);
                  } else {
                    ctx.commit('updateEvents', [...ctx.state.events, ...response.data.items]);
                  }

                  if (ctx.state.currentPage == 0) {
                    ctx.commit('setEventMaxPage', response.data.last_page);
                    ctx.commit('setEventCurrentPage', 1);
                  }
                });
              } else {
                serverError(response);
              }
            })
            .catch(() => {
              serverFailedAccess();
            });
      }
    },
    async fetchEventsCategories(ctx) {
      await fetch(
          `${API_URL}managers/channels/events/categories`,
          {
            method: 'GET',
            headers: {
              Authorization: `${localStorage.token_type} ${localStorage.access_token}`
            }
          }
      )
          .then((response) => {
            if (response.ok) {
              response.json().then((response) => {
                ctx.commit('updateEventsCategories', response.data);
              });
            } else {
              serverError(response);
            }
          })
          .catch(() => {
            serverFailedAccess();
          });
    },
    async addEvent(ctx, payload) {
      await fetch(
          `${API_URL}managers/channels/${localStorage.channelId}/events`,
          {
            body: payload,
            method: 'POST',
            headers: {
              Authorization: `${localStorage.token_type} ${localStorage.access_token}`
            },
          }
      )
          .then((response) => {
            if (response.ok) {
              response.json().then((response) => {
                ctx.commit('addEvent', response.data);
              });
            } else {
              serverError(response);
            }
          })
          .catch(() => {
            serverFailedAccess();
          });
    },
    async updateEvent(ctx, data) {
      await fetch(
          `${API_URL}managers/channels/${localStorage.channelId}/events/${data.eventId}`,
          {
            body: data.payload,
            method: 'POST',
            headers: {
              Authorization: `${localStorage.token_type} ${localStorage.access_token}`
            },
          }
      )
          .then((response) => {
            if (response.ok) {
              response.json().then((response) => {
                ctx.commit('updateEvent', response.data);
              });
            } else {
              serverError(response);
            }
          })
          .catch(() => {
            serverFailedAccess();
          });
    },
    async removeEventImage(ctx, eventId) {
      await fetch(
          `${API_URL}managers/channels/${localStorage.channelId}/events/${eventId}/remove-image`,
          {
            method: 'POST',
            headers: {
              Authorization: `${localStorage.token_type} ${localStorage.access_token}`
            },
          }
      )
          .then((response) => {
            if (!response.ok) {
              serverError(response);
            }
          })
          .catch(() => {
            serverFailedAccess();
          });
    },
    async removeEvent(ctx, data) {
      await fetch(
          `${API_URL}managers/channels/${localStorage.channelId}/events/${data.eventId}`,
          {
            body: data.payload,
            method: 'POST',
            headers: {
              Authorization: `${localStorage.token_type} ${localStorage.access_token}`
            },
          }
      )
          .then((response) => {
            if (response.ok) {
              ctx.commit('removeEvent', data.eventId);
            } else {
              serverError(response);
            }
          })
          .catch(() => {
            serverFailedAccess();
          });
    },
  }
}