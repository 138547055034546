export const API_URL = process.env.VUE_APP_PATH_START;

export const ASSISTANT_NAME = 'ТUЛЛUК';

import azbuka from '@/assets/img/promo/azbuka_car.jpg';
import adGormolzavod from '@/assets/img/promo/ad_gormolzavod.jpg';
import ad10 from '@/assets/img/promo/ad10.jpg';
import ad8 from '@/assets/img/promo/ad8.jpg';
import bread from '@/assets/img/promo/Yakut-bread.jpg';
import dia3 from '@/assets/img/promo/dia3.jpg';
import len1 from '@/assets/img/promo/len1.jpg';
import len2 from '@/assets/img/promo/len2.jpg';

export const PROMO_DATA = [
  {
    id: 0,
    channelId: 405,
    link: 'https://wa.me/79142708437',
    image: azbuka,
    description: 'ВЫИГРАЙ АВТОМОБИЛЬ <br> Азбука"',
  },
  {
    id: 1,
    channelId: 792,
    link: '',
    image: len1,
    description: 'ЖК Ленские Высоты',
  },
  {
    id: 2,
    channelId: 659,
    link: '',
    image: adGormolzavod,
    description: 'Якутский Гормолзавод <br> Новинка <br> "Молочный Дождик"',
  },
  {
    id: 3,
    channelId: 530,
    link: '',
    image: ad10,
    description: 'Сопровождаем сметы до <br> получения положительного <br> заключения в госэкспертизе',
  },
  {
    id: 4,
    channelId: 792,
    link: '',
    image: len2,
    description: 'ЖК Ленские Высоты',
  },
  {
    id: 5,
    channelId: 402,
    link: '',
    image: ad8,
    description: 'Мы создаем комфорт! <br> Зимние палатки от Хоту Тент',
  },
  {
    id: 6,
    channelId: 617,
    link: '',
    image: dia3,
    description: 'Diamond Clinic <br> Акция на УЗИ <br> -10%',
  },
  {
    id: 7,
    channelId: 535,
    link: '',
    image: bread,
    description: 'Якутский хлебокомбинат <br> Я❤хлеб',
  },
];
export const FEED_PROMO_DATA = [];

export const KZ_MOB_OPERATOR = [
  '700',
  '701',
  '702',
  '703',
  '704',
  '705',
  '706',
  '707',
  '708',
  '709',
  '747',
  '750',
  '751',
  '760',
  '761',
  '762',
  '763',
  '764',
  '771',
  '775',
  '776',
  '777',
  '778',
];