<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div v-click-outside="onClickOutside" class="modal-container">
          <div class="modal-header">
            <slot name="header">

            </slot>
          </div>
          <div class="modal-body">
            <slot name="body">

            </slot>
          </div>
          <div class="modal-footer">
            <slot name="footer">

            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import vClickOutside from "click-outside-vue3";

export default {
  name: 'custom-modal',
  directives: {
    clickOutside: vClickOutside.directive
  },
  methods: {
    onClickOutside() {
      this.$emit('closeModal')
    }
  },
}
</script>

<style lang="scss" scoped>
.test-modal {
  .modal-container {
    max-width: 650px!important;
  }

  .modal-footer,
  .modal-header {
    display: none!important;
  }

  .modal-body {
    height: max-content!important;
    min-height: max-content!important;
  }
}

@media (max-width: 1024px) {
  .test-modal {
    .modal-container {
      padding: 15px!important;
    }
  }
}

.auth-modal {
  .modal-wrapper .modal-container .modal-body {
    justify-content: start !important;
  }
}

.assistant-reset-modal {
  .modal-body {
    max-height: 100px!important;
    min-height: 100px!important;
    color: var(--color-dark-blue)!important;
  }
}

.modal-mask {
  position: fixed;
  z-index: 998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;

  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;

    .modal-container {
      max-width: 1500px;
      width: 70vw;
      max-height: 95vh;
      height: auto;
      margin: 0 auto;
      padding: 20px 30px;
      gap: 10px;
      background-color: var(--color-white);
      border-radius: 15px;
      box-shadow: 0 0 1px rgba(12, 26, 75, 0.03), 0 4px 20px -2px rgba(50, 50, 71, 0.04);
      transition: all 0.3s ease;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-direction: column;

      .modal-header {
        height: 64px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
      }

      .modal-body {
        display: block;
        justify-content: space-evenly;
        align-items: center;
        flex-direction: column;
        width: 100%;
        height: auto;
        min-height: 370px;
        overflow-y: auto;

        :slotted(input:not(.search)) {
          height: 60px;
          min-height: 60px;
          width: 100%;
          margin: 5px 0;
          border-radius: 15px;
          padding: 0 10px;
          background-color: var(--color-white);
          border: 1px solid var(--color-very-light-blue);

          &::placeholder {
            color: var(--color-gray);
          }

          &:active, &:hover, &:focus {
            outline: 0;
            outline-offset: 0;
          }
        }

        :slotted(textarea) {
          width: 100%;
          margin: 5px 0;
          border-radius: 15px;
          padding: 10px;
          background-color: var(--color-white);
          border: 1px solid var(--color-very-light-blue);
          height: 300px;
          text-align: start;

          &::placeholder {
            color: var(--color-gray);
          }

          &:active, &:hover, &:focus {
            outline: 0;
            outline-offset: 0;
          }
        }
      }

      .modal-footer {
        display: flex;
        justify-content: space-evenly;
        align-items: flex-end;
        width: 100%;
        height: 64px;
      }
    }
  }
}

:slotted(.title) {
  font-family: 'SF Pro Display Regular';
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 41px;
  color: var(--color-dark-blue);
}

.modal-body, .modal-footer {
  :slotted(button) {
    height: 100%;
    width: 100%;
    border: none;
    border-radius: 15px;
    margin: 0 15px;
    cursor: pointer;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
    padding: 0;
  }
}

.modal-body {
  :slotted(button) {
    height: 64px;
    margin: 5px 0;
  }

  :slotted(.stories) {
    justify-content: center;
    width: 100%;
    height: 180px;
    margin-top: 50px;

    button {
      width: 100% !important;
    }
  }

  :slotted(.auth-block) {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 50px;

    &>div {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      gap: 10px;
    }
  }

  :slotted(.auth-title-body) {
    width: 100%;
    text-align: start;
    color: var(--color-dark-blue);
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    font-family: 'SF Pro Text Medium';
  }

  :slotted(.auth-info) {
    width: 100%;
    text-align: start;
    color: var(--figma-color-dark-grey);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    font-family: 'SF Pro Text Medium';

    a {
      color: var(--color-blue);
      cursor: pointer;
    }
  }
}

:slotted(.btn-danger) {
  background-color: var(--color-light-red);
  color: var(--color-red);
}

:slotted(.btn-success) {
  background-color: var(--color-blue);
  color: var(--color-white);
}

:slotted(.btn-default) {
  background-color: var(--color-gray);
  color: var(--color-white);
}

:slotted(.close-modal) {
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: var(--color-gray);
  padding: 0;

  i {
    font-size: 36px;
  }
}

.recipient-modal {
  .modal-footer {
    display: none !important;
  }
}

:slotted(.modal-search) {
  width: 100%;
  margin: 5px 0;
}

:slotted(.modal-select) {
  width: 100%;
  margin: 5px 0;

  .select-lines {
    width: 90% !important;
  }

  button {
    width: 100% !important;
    background-position: 95% center !important;

    span {
      max-width: 85% !important;
    }
  }
}

:slotted(.document-header-error) {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: var(--color-gray);
  cursor: default;
}

.auth-modal {
  .modal-container {
    width: 650px !important;
    height: 490px !important;
    margin: 0 auto !important;

    .modal-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .title {
        margin: 0!important;
      }

      button {
        i {
          font-size: 28px;
          color: var(--color-dark-blue);
        }
      }
    }
  }

  @media (max-width: 1540px) {
    .modal-container {
      width: 550px !important;
      height: 460px !important;
    }
  }
}

.blogger-error {
  .modal-container {
    width: 620px !important;
    height: 170px !important;
    margin: 0 auto !important;
  }
}

.blogger-modal {
  .modal-container {
    width: 620px !important;
    height: 770px !important;
    margin: 0 auto !important;
  }

  @media (max-width: 1540px) {
    .modal-container {
      width: 540px !important;
      height: 540px !important;
    }

    .modal-body {

      :slotted(input:not(.search)) {
        height: 40px !important;
        min-height: 40px !important;
        border-radius: 13px !important;
      }
    }
  }

  .blogger-error {
    .modal-container {
      width: 650px !important;
      height: 170px !important;
      margin: 0 auto !important;
    }

    @media (max-width: 1540px) {
      .modal-container {
        width: 500px !important;
        height: 150px !important;
      }
    }
  }

  @media (max-width: 1152px) {
    .modal-mask .modal-wrapper .modal-container {
      width: 80vw;
    }
  }


  @media (max-width: 680px) {
    .modal-mask .modal-wrapper .modal-container {
      width: 90vw !important;
      height: 90vh !important;
      max-height: 100vh !important;
      border-radius: 15px;
      padding: 5px;

      .modal-body {
        display: block;
      }
    }

    .registerModal {
      .modal-footer {
        padding-bottom: 50px;
      }
    }
  }
}

@media (max-width: 680px) {
  .modal-mask {
    .modal-wrapper {
      .modal-container {
        max-width: 98vw;
        width: 98vw;
        height: auto;
        max-height: 90vh;
        border-radius: 0;
        padding: 5px;

        .modal-body {
          display: block;
        }
      }
    }
  }
}

.delete-modal .modal-body {
  height: 20px!important;
  min-height: 20px!important;
  max-height: 20px!important;
}
</style>